import { createAction, props } from "@ngrx/store";
import { Conversation } from "app/api-models/conversation.models";

export const getConversation = createAction("[Conversation API] Get Conversation", props<{ id: string }>());

export const setConversation = createAction(
  "[Conversation] Set Conversation",
  props<{ conversation: Conversation[] }>(),
);

export const addConversation = createAction(
  "[Conversation] Add Conversation",
  props<{ conversation: Conversation[] }>(),
);

export const deleteConversations = createAction(
  "[Conversation] Delete Brain Conversations",
  props<{ conversationId: string[]; brainId: string }>(),
);

export const clearConversationState = createAction("[Conversation] Clear State");

export const updateLastMessage = createAction(
  "[Conversation] Update Last Message",
  props<{ conversation: Conversation }>(),
);

export const updateMessageById = createAction(
  "[Conversation] Update Conversation By Id",
  props<{ conversation: Conversation }>(),
);

export const startStream = createAction(
  "[Conversation] Start Conversation Stream",
  props<{ text: string; conversation: Conversation }>(),
);

export const updateStream = createAction(
  "[Conversation] Update Conversation Stream",
  props<{ streamedText: string }>(),
);

export const cancelStream = createAction("[Conversation] Cancel Conversation Stream");
