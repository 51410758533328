import { Action, createReducer, on } from "@ngrx/store";
import { Conversation } from "app/api-models/conversation.models";
import * as ConversationActions from "app/store/actions/conversation.action";
import { ChatDataRole } from "app/pages/my-brain/my-brain.model";

export interface ConversationState {
  conversation: Conversation[] | null;
  error: string | null;
  streamedText: string;
  isStreaming: boolean;
}

export const initialConversationState: ConversationState = {
  conversation: null,
  error: null,
  streamedText: "",
  isStreaming: false,
};

export const conversationReducer = createReducer(
  initialConversationState,
  on(ConversationActions.setConversation, (state, { conversation }) => ({
    ...state,
    conversation,
  })),
  on(ConversationActions.addConversation, (state, { conversation }) => ({
    ...state,
    conversation: state.conversation ? [...state.conversation, ...conversation] : [...conversation],
  })),
  on(ConversationActions.clearConversationState, (state) => ({
    ...state,
    conversation: null,
  })),
  on(ConversationActions.updateLastMessage, (state, { conversation }) => {
    if (state.conversation && state.conversation.length > 0) {
      const updatedConversations = [...state.conversation];

      const lastIndex = state.conversation
        .slice()
        .reverse()
        .findIndex((conv) => conv.role === conversation.role);

      const actualIndex = lastIndex !== -1 ? state.conversation.length - 1 - lastIndex : -1;

      if (actualIndex !== -1) {
        if (conversation.role === ChatDataRole.user) {
          updatedConversations[actualIndex] = {
            ...updatedConversations[actualIndex],
            id: conversation.id || updatedConversations[actualIndex].id,
          };
        } else if (conversation.role === ChatDataRole.assistant) {
          updatedConversations[actualIndex] = updatedConversations[actualIndex].id
            ? {
                ...updatedConversations[actualIndex],
                ...conversation,
              }
            : {
                ...updatedConversations[actualIndex],
                id: conversation.id,
              };
        } else {
          updatedConversations[actualIndex] = {
            ...updatedConversations[actualIndex],
            ...conversation,
          };
        }
      }

      return {
        ...state,
        conversation: updatedConversations,
      };
    }

    return state;
  }),
  on(ConversationActions.updateMessageById, (state, { conversation }) => ({
    ...state,
    conversation: state.conversation
      ? state.conversation.map((existingConversation) =>
          existingConversation.id === conversation.id ? { ...conversation } : existingConversation,
        )
      : [],
  })),
  on(ConversationActions.startStream, (state) => ({
    ...state,
    isStreaming: true,
    streamedText: "",
  })),
  on(ConversationActions.updateStream, (state, { streamedText }) => ({
    ...state,
    streamedText,
  })),
  on(ConversationActions.cancelStream, (state) => ({
    ...state,
    isStreaming: false,
    streamedText: "",
  })),
);

export function reducer(state = initialConversationState, action: Action): ConversationState {
  return conversationReducer(state, action);
}
