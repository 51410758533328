import { EmbeddingFile } from "app/app.model";
import { Action, createReducer, on } from "@ngrx/store";
import {
  addEmbeddingFile,
  clearEmbeddingFileState,
  removeEmbeddingFile,
  setEmbeddingFile,
  updateEmbeddingFile,
  updateNewlyAddedStatus,
} from "app/store/actions/embedding-file.action";

export interface EmbeddingFileState {
  embeddingFile: EmbeddingFile[] | null;
  newlyAdded: boolean; // This will track if the latest update is new content
  error: string | null;
}

export const initialEmbeddingFileState: EmbeddingFileState = {
  embeddingFile: null,
  newlyAdded: false,
  error: null,
};

export const embeddingFileReducer = createReducer(
  initialEmbeddingFileState,
  on(setEmbeddingFile, (state, { embeddingFile }) => ({
    ...state,
    embeddingFile: embeddingFile.map((file) => ({ ...file, newlyAdded: false })),
    error: null,
  })),

  on(addEmbeddingFile, (state, { brainId, embeddingFile, newlyAdded }) => {
    const newFiles = embeddingFile
      .filter((file) => file.projectId === brainId)
      .map((file) => ({ ...file, newlyAdded }));

    const existingFilesMap = new Map(state.embeddingFile?.map((file) => [file.fileName || file.webpage, file]));

    newFiles.forEach((newFile) => {
      if (existingFilesMap.has(newFile.fileName || newFile.webpage)) {
        const existingFile = existingFilesMap.get(newFile.fileName || newFile.webpage);

        const updatedNewlyAdded = newlyAdded !== undefined ? newlyAdded : existingFile?.newlyAdded;

        existingFilesMap.set(newFile.fileName || newFile.webpage, {
          ...existingFile,
          ...newFile,
          newlyAdded: updatedNewlyAdded,
        });
      } else {
        existingFilesMap.set(newFile.fileName || newFile.webpage, newFile);
      }
    });

    const updatedFiles = Array.from(existingFilesMap.values());

    return {
      ...state,
      embeddingFile: updatedFiles,
      error: null,
    };
  }),

  on(updateEmbeddingFile, (state, { brainId, embeddingFile }) => {
    const updatedFiles = (state.embeddingFile || []).map((file) => {
      if (file.fileName === embeddingFile.fileName && file.projectId === brainId) {
        return { ...file, ...embeddingFile };
      }
      return file;
    });

    return {
      ...state,
      embeddingFile: updatedFiles,
      error: null,
    };
  }),

  on(updateNewlyAddedStatus, (state, { brainId }) => ({
    ...state,
    embeddingFile: state.embeddingFile
      ? state.embeddingFile
          .filter((file) => file.projectId === brainId)
          .map((item) => ({
            ...item,
            newlyAdded: false,
          }))
      : null,
  })),

  on(removeEmbeddingFile, (state, { brainId, embeddingFile }) => {
    const idsToRemove = new Set(embeddingFile.map((file) => file.id || file.webpage));

    return {
      ...state,
      embeddingFile: state.embeddingFile
        ? state.embeddingFile.filter(
            (file) => !(file.projectId === brainId && idsToRemove.has(file.id || file.webpage)),
          )
        : null,
    };
  }),

  on(clearEmbeddingFileState, (state) => ({
    ...state,
    embeddingFile: null,
    error: null,
  })),
);

export function reducer(state = initialEmbeddingFileState, action: Action): EmbeddingFileState {
  return embeddingFileReducer(state, action);
}
