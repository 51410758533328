/* eslint-disable max-lines */
import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TranslocoModule } from "@ngneat/transloco";
import { AppStore } from "app/app-store.service";
import { BrainContentType, EmbeddingFile, EmbeddingFileStatus, FileCreationRequest, ProgressData } from "app/app.model";
import { AddedBrainContentComponent } from "app/core/components/added-brain-content/added-brain-content.component";
import { WebPageUploadComponent } from "app/core/components/web-page-upload/web-page-upload.component";
import { isRegularUser } from "app/core/functions/helper-functions";
import { PollingService } from "app/core/services/polling.service";
import { BrainLimitDirective } from "app/directives/brain-limit.directive";
import { environment } from "app/environments/environment";
import { Brain } from "app/pages/dashboard/dashboard.model";
import { UserData, UserDataLimits } from "app/pages/home/home.model";
import { EmbeddingFileService } from "app/services/embedding-files.service";
import { UsersService } from "app/services/users.service";
import { finalize, from, map, mergeMap, of, takeUntil } from "rxjs";
import { BaseHttpComponent } from "../base-http/base-http.component";
import { addEmbeddingFile, deleteEmbeddingFiles, updateEmbeddingFile } from "app/store/actions/embedding-file.action";
import { setUser } from "app/store/actions/user.actions";
import { UserDataLimitsService } from "app/services/user-data-limits.service";

@Component({
  selector: "app-file-upload",
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    TranslocoModule,
    FormsModule,
    MatProgressSpinnerModule,
    BrainLimitDirective,
    AddedBrainContentComponent,
    WebPageUploadComponent,
    MatExpansionModule,
  ],
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FileUploadComponent extends BaseHttpComponent implements OnInit {
  isError = false;
  errorMessage: string[] = [];
  extensions: string[] = environment.allowedFiles;
  maxSize = 25 * 1024 * 1000;
  archiveMaxSize = 2 * 1024 * 1024 * 1000;
  selectedBrain?: Brain;
  userData?: UserData;
  userDataLimits!: UserDataLimits;
  contents: EmbeddingFile[] = [];
  allBrainContents: EmbeddingFile[] = [];
  completedContents = 0;
  filesStr!: string;
  filesAccept!: string;
  uploadProgress = 0;

  @ViewChild("uploadFile") fileInput?: ElementRef;
  @Input() webPageUploading = false;
  @Input() fileCount = 0;
  @Output() fileBrainContents = new EventEmitter<EmbeddingFile[]>();
  @Output() isUploading = new EventEmitter<boolean>();
  uploadingInProgress = false;

  constructor(
    private embeddingFileService: EmbeddingFileService,
    private userService: UsersService,
    private pollingService: PollingService,
    private userLimitService: UserDataLimitsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.brain$.pipe(takeUntil(this.destroy$)).subscribe((brain) => {
      if (brain) {
        this.selectedBrain = brain;
      }
    });

    this.embeddingFiles$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data) {
        this.allBrainContents = data;
        this.contents = this.allBrainContents.filter(
          (files) => files.newlyAdded === true && files.contentType === BrainContentType.File,
        );
        console.log(this.contents);
      }
    });

    this.user$.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
        this.userDataLimits = this.userLimitService.checkUserLimit(userData);
      }
    });

    if (isRegularUser(this.userData)) {
      const restrictedFiles = ["pptx", "csv", "xls", "xlsx", "numbers"];
      this.extensions = this.extensions.filter((ext) => !restrictedFiles.includes(ext));
    }
    this.filesStr = this.extensions.join(", ").toUpperCase();
    this.filesAccept = this.extensions.map((ext) => [`.${ext.toLowerCase()}`, `.${ext.toUpperCase()}`]).join(", ");

    AppStore.zipUploadProgress$.subscribe((progressDataArray: ProgressData[] | null | undefined) => {
      if (!progressDataArray) return;

      const updatedContents = this.contents.map((content) => {
        const progressData = progressDataArray.find((data) => data.FileName === content.fileName);

        if (progressData && content.status === EmbeddingFileStatus.Uploading && progressData.Progress === 100) {
          return { ...content, status: EmbeddingFileStatus.Processing };
        }
        return content;
      });

      this.store.dispatch(
        addEmbeddingFile({
          brainId: this.selectedBrain?.id as string,
          embeddingFile: updatedContents,
          newlyAdded: true,
        }),
      );
      this.fileBrainContents.emit(this.contents);
    });
  }

  private validateFiles(files: File[]) {
    this.errorMessage = [];
    this.isError = false;
    const validFiles: File[] = [];
    const brainFiles = this.allBrainContents;
    files.forEach((file) => {
      const extension = file.name.split(".").reverse()[0].toLowerCase();
      const isArchive = extension === "zip" || extension === "7z";
      const maxAllowedSize = isArchive ? this.archiveMaxSize : this.maxSize;

      if (file && this.extensions.indexOf(extension) === -1) {
        this.errorMessage.push(
          this.transloco.translate("addMyBrainContent.file") +
            ` ${file.name} ` +
            this.transloco.translate("addMyBrainContent.notSupported") +
            ` ${this.extensions.join(", ")}`,
        );
        return;
      } else if (file && file.size > maxAllowedSize) {
        this.errorMessage.push(
          this.transloco.translate("addMyBrainContent.fileSize") +
            ` ${(file.size / (1000 * 1000)).toFixed(2)}` +
            this.transloco.translate("addMyBrainContent.maxLength") +
            `${maxAllowedSize / (1000 * 1000)}mb`,
        );
      } else if (brainFiles.find((fileData) => fileData.fileName === file.name && fileData.fileSize === file.size)) {
        this.errorMessage.push(this.transloco.translate("addMyBrainContent.fileUploaded"));
      } else {
        validFiles.push(file);
      }
    });

    this.isError = this.errorMessage.length > 0;
    return validFiles;
  }
  private getFiles(list: FileList) {
    const files: File[] = [];
    if (this.userData) {
      for (
        let index = 0;
        index < list.length && index < this.userData.contentFileLimit - this.userData.contentFileCount;
        index++
      ) {
        files.push(list[index]);
      }
    }

    return files;
  }

  onFileChange(event: Event) {
    this.isError = false;
    this.errorMessage = [];
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length) {
      const files = this.getFiles(input.files);
      const validFiles = this.validateFiles(files);

      input.value = "";

      if (!validFiles.length) return;
      this.uploadAndAddAsBrainContent(validFiles);
    }
  }

  uploadAndAddAsBrainContent(filesToUpload: File[]) {
    if (!filesToUpload.length) return;

    this.isUploading.emit(true);
    this.uploadingInProgress = true;
    this.uploadProgress = 0;

    const uploadingContents = filesToUpload.map((file) => {
      return {
        fileName: file.name,
        metadata: { title: file.name },
        contentType: BrainContentType.File,
        fileExtension: file.name.split(".").reverse()[0],
        status: EmbeddingFileStatus.Uploading,
        fileSize: file.size / 1000,
        projectId: this.selectedBrain?.id,
      } as Partial<EmbeddingFile>;
    }) as EmbeddingFile[];

    this.store.dispatch(
      addEmbeddingFile({
        brainId: this.selectedBrain?.id as string,
        embeddingFile: uploadingContents,
        newlyAdded: true,
      }),
    );
    this.cdr.markForCheck();

    const uploadAndProcessFile = (file: File) => {
      if (!this.selectedBrain || !this.userData) {
        return of({ file, response: null, initiationResult: null, ingestResult: null, pollingResult: null });
      }
      const extension = file.name.split(".").reverse()[0].toLowerCase();
      const isArchive = extension === "zip" || extension === "7z";

      if (isArchive) {
        const formData = new FormData();
        formData.append("zipFile", file);
        formData.append("projectId", this.selectedBrain.id);

        return this.embeddingFileService.uploadLargeFile(file, this.selectedBrain.id, (progress: any) => {
          console.log(`Upload progress: ${progress}%`);

          const fileName = (formData.get("zipFile") as File)?.name;
          const parsedData: ProgressData = {
            Progress: Math.floor(progress),
            CurrentFile: fileName,
            FileName: fileName,
          };

          const currentProgress = AppStore.zipUploadProgress$.getValue() || [];
          const existingFileIndex = currentProgress.findIndex((p) => p.FileName === fileName);
          parsedData.FileName = fileName;

          if (existingFileIndex !== -1) {
            // Update existing entry
            const updatedProgress = [...currentProgress];
            updatedProgress[existingFileIndex] = parsedData;
            AppStore.zipUploadProgress$.next(updatedProgress);
          } else {
            // Add new entry
            AppStore.zipUploadProgress$.next([...currentProgress, parsedData]);
          }
          // Update your UI with progress
        });
      }

      return this.embeddingFileService.uploadFile(file).pipe(
        mergeMap((response) => {
          if (!response.isSuccess || !response.data) {
            // uploadFile failed
            return of({ file, response, initiationResult: null, ingestResult: null, pollingResult: null });
          }
          const fileData = response.data;
          fileData.name = file.name;
          if (fileData.mediaLink && !fileData.url) {
            fileData.url = fileData.mediaLink;
          }
          const currentContent = this.contents.find((x) => x.fileName == file.name);
          if (currentContent) {
            fileData.contentType = currentContent.fileExtension;
          }
          if (currentContent) {
            const uploadFile = {
              ...currentContent,
              status: EmbeddingFileStatus.Uploading,
            };
            this.updateCurrentfile(uploadFile);
          }

          const creationRequest: FileCreationRequest = {
            projectId: this.selectedBrain?.id as string,
            fileData: fileData,
          };
          return this.embeddingFileService.initiateFileData(creationRequest).pipe(
            mergeMap((initiationResult) => {
              if (!initiationResult.isSuccess || !initiationResult.data) {
                // initiateFileData failed
                this.errorMessage.push(this.transloco.translate("addMyBrainContent.fileUploaded"));
                this.contents = this.contents.filter((x) => x.fileName?.toLowerCase() == file.name?.toLowerCase());
                // AppStore.addedBrainContents$.next(this.contents);
                return of({ file, response, initiationResult, ingestResult: null, pollingResult: null });
              }
              this.updateCurrentfile(initiationResult.data);

              this.cdr.markForCheck();
              this.completedContents = this.contents.length;

              return this.userService.get().pipe(
                mergeMap((response) => {
                  if (!response?.isSuccess || !response?.data || !initiationResult?.data) {
                    // userService get failed
                    return of({ file, response, initiationResult, ingestResult: null, pollingResult: null });
                  }
                  this.store.dispatch(setUser({ user: response.data }));

                  this.userData = response.data;
                  this.uploadingInProgress = false;
                  this.isUploading.emit(false);
                  this.cdr.markForCheck();
                  return this.embeddingFileService.ingestFileBackground(initiationResult.data).pipe(
                    mergeMap((ingestResult) => {
                      if (!ingestResult.isSuccess || !initiationResult.data) {
                        // ingestFileBackground failed
                        return of({
                          file,
                          response,
                          initiationResult,
                          ingestResult,
                          pollingResult: null,
                          userResponse: null,
                        });
                      }
                      // Start polling after ingestion is initiated
                      return this.pollingService.pollingEmbeddingFileStatus(initiationResult.data).pipe(
                        mergeMap((pollingResult) => {
                          if (pollingResult.isSuccess && pollingResult.data) {
                            return this.userService.get().pipe(
                              map((userResponse) => ({
                                file,
                                response,
                                initiationResult,
                                ingestResult,
                                pollingResult,
                                userResponse,
                              })),
                            );
                          }
                          return of({
                            file,
                            response,
                            initiationResult,
                            ingestResult,
                            pollingResult,
                            userResponse: null,
                          });
                        }),
                      );
                    }),
                  );
                }),
              );
            }),
          );
        }),
      );
    };

    from(filesToUpload)
      .pipe(
        mergeMap(uploadAndProcessFile, 5), // Adjust the concurrency level as needed
        finalize(() => {
          this.isUploading.emit(false);
          this.uploadingInProgress = false;
          this.uploadProgress = 0;
          this.cdr.markForCheck();
        }),
      )
      .subscribe({
        next: (result) => {
          if (result?.pollingResult?.isSuccess && result.pollingResult.data) {
            if (result.pollingResult?.data) {
              this.updateCurrentfile(result.pollingResult.data);
            }
          }
        },
        error: (err) => {
          console.error(err);
          this.deleteContentsOnError(uploadingContents);
          this.isUploading.emit(false);
          this.uploadingInProgress = false;
          this.uploadProgress = 0;
          this.cdr.markForCheck();
        },
        complete: () => {
          this.isUploading.emit(false);
          this.uploadingInProgress = false;
          this.uploadProgress = 0;
          this.cdr.markForCheck();
        },
      });
  }

  updateCurrentfile(embeddingFile: EmbeddingFile) {
    this.store.dispatch(
      updateEmbeddingFile({
        brainId: this.selectedBrain?.id as string,
        embeddingFile: embeddingFile,
      }),
    );
    this.cdr.markForCheck();
  }

  deleteContentsOnError(contents: EmbeddingFile[]) {
    if (this.errorMessage) {
      this.errorMessage = [];
    }
    this.errorMessage.push(this.transloco.translate("addMyBrainContent.uploadErrMsg"));
    contents.forEach((currentContent) => {
      const content = this.contents.find(
        (x) => x.fileName === currentContent.fileName || x.metadata?.title === currentContent.fileName,
      );

      if (!content) {
        return;
      }

      this.store.dispatch(deleteEmbeddingFiles({ brainId: this.selectedBrain?.id as string, content: [content] }));
      this.cdr.markForCheck();
    });
  }

  deleteContent(currentContent: EmbeddingFile) {
    if (this.errorMessage) {
      this.errorMessage = [];
    }

    const content = this.contents.find(
      (x) => x.fileName === currentContent.fileName || x.metadata?.title === currentContent.fileName,
    );

    if (!content) {
      return;
    }
    this.store.dispatch(deleteEmbeddingFiles({ brainId: this.selectedBrain?.id as string, content: [content] }));
    this.cdr.markForCheck();
  }
}
